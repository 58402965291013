import React from 'react';
import styles from './Error.module.css';
import wrong from '../../assets/wrong.svg';
import Btn from '../Btn/Btn';

const Error = () => (
        <div className={styles.errBox}>
            <img className={styles.errIcon} src={wrong} alt='Warning Icon' />
            <h1 className={styles.errHeader}>Ooops,</h1>
            <p className={styles.errText}>
                <span>Something went wrong!</span>
                <span>Let's try again</span>
            </p>
            <Btn onClick={_ => window.location.reload()} text="TRY AGAIN" />
        </div>
);
 
export default Error;