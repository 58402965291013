import React from "react";
import styles from './Bicycle.module.css';

const Bicycle = () => {
  return (
    <svg
    className={styles.bicycle}
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      x="0"
      y="0"
      enableBackground="new 0 0 451.529 451.529"
      version="1.1"
      viewBox="0 0 451.529 451.529"
      xmlSpace="preserve"
    >
      <g fill="#0e3c5e">
        <path d="M367.98 374.108c-46.068 0-83.548-37.48-83.548-83.549 0-46.07 37.48-83.549 83.548-83.549 46.069 0 83.549 37.479 83.549 83.549 0 46.069-37.479 83.549-83.549 83.549zm0-140.683c-31.503 0-57.133 25.631-57.133 57.135s25.63 57.133 57.133 57.133c31.505 0 57.134-25.629 57.134-57.133s-25.629-57.135-57.134-57.135z"></path>
        <path
          d="M367.98 233.425v-26.414c-46.068 0-83.548 37.479-83.548 83.549 0 46.068 37.48 83.549 83.548 83.549v-26.416c-31.503 0-57.133-25.629-57.133-57.133s25.631-57.135 57.133-57.135z"
          opacity="0.4"
        ></path>
      </g>
      <g fill="#0e3c5e">
        <path d="M83.549 374.108C37.479 374.108 0 336.628 0 290.559c0-46.07 37.479-83.549 83.549-83.549 46.067 0 83.548 37.479 83.548 83.549 0 46.069-37.481 83.549-83.548 83.549zm0-140.683c-31.505 0-57.134 25.631-57.134 57.135s25.629 57.133 57.134 57.133c31.503 0 57.133-25.629 57.133-57.133s-25.63-57.135-57.133-57.135z"></path>
        <path
          d="M83.549 233.425v-26.414C37.479 207.011 0 244.489 0 290.559c0 46.068 37.479 83.549 83.549 83.549v-26.416c-31.505 0-57.134-25.629-57.134-57.133s25.629-57.134 57.134-57.134zM122.259 216.556l-13.721 22.637a57.462 57.462 0 0116.072 11.701l13.998-23.092a83.782 83.782 0 00-16.349-11.246zm18.373 75.986a56.758 56.758 0 01-4.205 19.621l27.42 1.408a82.993 82.993 0 003.164-19.672l-26.379-1.357z"
          opacity="0.4"
        ></path>
      </g>
      <path
        fill="#f1c40f"
        d="M152.268 187.1a7.501 7.501 0 01-6.855-4.453l-18.248-41a7.498 7.498 0 013.802-9.9 7.499 7.499 0 019.901 3.801l18.248 41a7.5 7.5 0 01-6.848 10.552z"
      ></path>
      <path
        fill="#0e3c5e"
        d="M140.868 135.548a7.5 7.5 0 00-13.703 6.099l5.859 13.164c4.396-1.115 9.603-2.428 14.753-3.738l-6.909-15.525z"
        opacity="0.3"
      ></path>
      <path
        fill="#0e3c5e"
        d="M176.938 122.548c.336-8.314-6.132-15.328-14.447-15.662l-48.265-1.949c-12.27-.496-22.616 9.049-23.112 21.318-.495 12.268 9.05 22.615 21.319 23.111a22.19 22.19 0 006.912-.818l.001.008c6.932-1.885 38.026-9.523 46.412-12.051l.15-.049c.421-.127.799-.244 1.087-.344l-.001-.004c5.593-2.021 9.69-7.254 9.944-13.56z"
      ></path>
      <path
        fill="#0e3c5e"
        d="M176.938 122.548H91.6a22.136 22.136 0 00-.485 3.707c-.495 12.268 9.05 22.615 21.319 23.111a22.19 22.19 0 006.912-.818l.001.008c6.932-1.885 38.026-9.523 46.412-12.051l.15-.049c.421-.127.799-.244 1.087-.344l-.001-.004c5.592-2.021 9.689-7.254 9.943-13.56z"
        opacity="0.4"
      ></path>
      <path
        fill="#47d4d3"
        d="M367.98 297.038a7.503 7.503 0 01-7.091-5.061l-44.085-127.99-7.31-50.646a7.5 7.5 0 1114.846-2.142l7.109 49.244 43.622 126.65a7.498 7.498 0 01-4.648 9.533 7.483 7.483 0 01-2.443.412z"
      ></path>
      <path
        fill="#0e3c5e"
        d="M316.91 119.77a7.46 7.46 0 01-4.202-1.295l-25.223-17.104a7.498 7.498 0 01-1.998-10.416 7.497 7.497 0 0110.416-1.998l25.223 17.104a7.498 7.498 0 011.998 10.416 7.493 7.493 0 01-6.214 3.293z"
      ></path>
      <path
        fill="#0e3c5e"
        d="M295.903 88.958a7.497 7.497 0 00-10.416 1.998 7.498 7.498 0 001.998 10.416l18.207 12.348a23.257 23.257 0 008.42-12.414l-18.209-12.348z"
        opacity="0.4"
      ></path>
      <path
        fill="#0e3c5e"
        d="M309.438 95.167c0-9.803-7.943-17.746-17.744-17.746-9.8 0-17.741 7.943-17.741 17.746 0 9.799 7.941 17.74 17.741 17.74s17.744-7.941 17.744-17.74z"
      ></path>
      <circle cx="291.695" cy="95.167" r="6.444" fill="#f1c40f"></circle>
      <path
        fill="#0e3c5e"
        d="M291.693 77.421c-9.8 0-17.741 7.943-17.741 17.746 0 9.799 7.941 17.74 17.741 17.74V77.421z"
        opacity="0.4"
      ></path>
      <path
        fill="#47d4d3"
        d="M328.496 165.249a9.904 9.904 0 00-9.414-5.564l-167.878 8.539c-.066.004-.129.016-.196.025-.165.01-.326.033-.489.051-.27.035-.539.072-.806.129-.145.029-.288.066-.431.102-.279.072-.556.15-.826.246a8.083 8.083 0 00-.376.143c-.277.107-.55.229-.815.365a9.35 9.35 0 00-1.105.649c-.109.072-.218.145-.325.221-.235.178-.46.363-.681.561-.104.092-.21.178-.31.275-.211.201-.406.42-.599.641-.093.107-.19.205-.278.314a10.59 10.59 0 00-.563.797c-.053.086-.116.158-.168.242l-68.158 112.44a9.898 9.898 0 00-.282 9.771 9.901 9.901 0 008.245 5.256l124.808 6.416a9.716 9.716 0 001.657-.058c.128-.016.251-.041.376-.06.251-.037.501-.082.748-.141.144-.033.285-.076.428-.117a9.295 9.295 0 001.717-.664c.136-.066.271-.135.402-.209.207-.115.407-.244.607-.373.117-.078.239-.15.355-.236.216-.154.42-.322.623-.496.09-.074.182-.142.268-.221.276-.252.539-.517.788-.801l.023-.027.011-.012L327.046 176.09a9.91 9.91 0 001.45-10.841zM100.611 281.52l50.05-82.572 41.601 87.281-91.651-4.709zm110.2-2.418l-32.609-68.42 96.623-4.9-64.014 73.32z"
      ></path>
      <path
        fill="#f1c40f"
        d="M228.759 193.808a7.5 7.5 0 01-.356-14.991l53.316-2.582c4.126-.189 7.653 2.992 7.854 7.129s-2.991 7.654-7.129 7.854l-53.316 2.582a7.626 7.626 0 01-.369.008z"
      ></path>
      <path
        fill="#0e3c5e"
        d="M210.811 165.19l-59.607 3.033c-.066.004-.129.016-.196.025-.165.01-.326.033-.489.051-.27.035-.539.072-.806.129-.145.029-.288.066-.431.102-.279.072-.556.15-.826.246a8.083 8.083 0 00-.376.143c-.277.107-.55.229-.815.365a9.35 9.35 0 00-1.105.649c-.109.072-.218.145-.325.221-.235.178-.46.363-.681.561-.104.092-.21.178-.31.275-.211.201-.406.42-.599.641-.093.107-.19.205-.278.314a10.59 10.59 0 00-.563.797c-.053.086-.116.158-.168.242L75.078 285.425a9.898 9.898 0 00-.282 9.771 9.901 9.901 0 008.245 5.256l124.808 6.416a9.716 9.716 0 001.657-.058c.128-.016.251-.041.376-.06.251-.037.501-.082.748-.141.06-.014.121-.033.182-.047v-27.459l-32.609-68.42 32.609-1.652V165.19zm-18.549 121.039l-91.65-4.709 50.05-82.572 41.6 87.281z"
        opacity="0.3"
      ></path>
      <g>
        <path
          fill="#f1c40f"
          d="M233.689 296.975c0-13.988-11.341-25.334-25.333-25.334-13.99 0-25.333 11.346-25.333 25.334 0 13.99 11.343 25.332 25.333 25.332 13.993.001 25.333-11.341 25.333-25.332z"
        ></path>
        <circle cx="208.356" cy="296.975" r="13.519" fill="#0e3c5e"></circle>
        <path
          fill="#0e3c5e"
          d="M208.356 271.642c-13.99 0-25.333 11.346-25.333 25.334 0 13.99 11.343 25.332 25.333 25.332v-50.666z"
          opacity="0.2"
        ></path>
        <path
          fill="#f1c40f"
          d="M230.643 338.849c-2.483 0-4.906-.438-6.334-2.689l-22.288-35.17a7.5 7.5 0 0112.67-8.03l22.288 35.17a7.5 7.5 0 01-2.32 10.35c-1.245.788-2.642.369-4.016.369z"
        ></path>
        <path
          fill="#0e3c5e"
          d="M236.979 328.13l-4.932-7.781H214.29l10.02 15.811c1.428 2.252 3.851 2.689 6.334 2.689 1.373 0 2.771.42 4.016-.369a7.501 7.501 0 002.319-10.35z"
          opacity="0.2"
        ></path>
        <path
          fill="#47d4d3"
          d="M211.658 324.645H249.63V339.645H211.658z"
        ></path>
      </g>
    </svg>
  )
}

export default Bicycle