import React, { useEffect, useRef, useState } from 'react'
import styles from './Qs.module.css';
import getWords from '../../services/getWords';
import Btn from '../Btn/Btn';
import Banana from '../SVG/Banana/Banana';
import Bicycle from '../SVG/Bicycle/Bicycle';
import Orange from '../SVG/Orange/Orange';
import Sneakers from '../SVG/Sneakers/Sneakers';
import correctSound from '../../assets/correctSound.mp3'
import wrongSound from '../../assets/wrongSound.mp3'
import correct from '../../assets/correct.svg';
import wrong from '../../assets/wrong.svg';

const Qs = ({score, setScore, setIsFinished, setIsLoading, setIsError}) => {
  const [words, setWords] = useState(null);
  const [cats, setCats] = useState(null)
  const [que, setQue] = useState(null);
  const [ans, setAns] = useState(null);

  const barRef = useRef();
  const listRef = useRef();

  useEffect(() => {
    getWords()
    .then(res => {
    setCats(res.cats);
    res.list.map((w, i) => w.id = i);
    setWords(res.list);
    setQue(res.list[0]);
    setIsLoading(false);
    })
    .catch(_ => setIsError(true))
  }, []);
  
  useEffect(() => {
    const bar = barRef.current;
    if(!bar) return;

    bar.style.width = `${((que.id + 1) / words.length) * 100}%`;
  }, [que]);


  const selectAns = (cat) => {
      const list = listRef.current; 
      if(!list) return;

      let items = list.querySelectorAll('li');
      if(!items) return;

      const selectedItem = list.querySelector(`#${cat}`);
      if(!selectedItem) return;

      items = Array.from(items);
      items.forEach(i => i.classList.remove(styles.selected));

      setAns(cat);
      selectedItem.classList.add(styles.selected);
  }
  const checkAns = (e) => {
    const target = e.target;
    if(!target) return;

    const list = target.previousElementSibling;
    if(!list) return;

    let items = list.querySelectorAll('li');
    items = Array.from(items);

    const selectedItem = list.querySelector(`#${ans}`);
    if(!selectedItem) return;

    const audio = new Audio();

    target.classList.add(styles.disabled)
    items.forEach(i => i.classList.add(styles.disabled));

    if(ans === que.pos) {
      audio.src = correctSound;
      audio.play();
      setScore(score + 10);
      selectedItem.classList.add(styles.correct);
    } else {
      audio.src = wrongSound;
      audio.play();
      selectedItem.classList.add(styles.wrong);
    }

    let timer;
    clearTimeout(timer);

    timer = setTimeout(() => {
      selectedItem.classList.remove(styles.selected);
      selectedItem.classList.remove(styles.correct);
      selectedItem.classList.remove(styles.wrong);

      items.forEach(i => i.classList.remove(styles.disabled));
      target.classList.remove(styles.disabled);

      setAns(null);

      const nextQue = words[que.id + 1];
      nextQue ? setQue(words[que.id + 1]) : setIsFinished(true);
    }, 2000);
  }
  return (
    <>
      {que && cats && <div className={styles.qs}>
      <Banana />
      <Orange />
      <Sneakers />
      <Bicycle />
      <div ref={barRef} className={styles.bar}></div>
      <div className={styles.header}>
        <h1 className={styles.headerText}>{que.word}</h1>
        <div className={styles.border}></div>
      </div>
      <div className={styles.body}>
        <ul ref={listRef} className={styles.opts}>
            {cats.map((cat, i) => (
                <li key={cat} id={cat} onClick={_ => selectAns(cat)} className={styles.opt}>
                <span className={styles.optNum}>{i + 1}</span>
                <span className={styles.optText}>{cat}</span>
                <img className={`${styles.icon} ${styles.correctIcon}`} src={correct} alt='Correct Answer Icon' />
                <img className={`${styles.icon} ${styles.wrongIcon}`} src={wrong}  alt='Wrong Answer Icon' />
                </li>
            ))}
        </ul>
        <Btn onClick={checkAns} text='Next'/>
      </div>
    </div>}
    </>
  )
}

export default Qs;