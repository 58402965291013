import React from 'react'
import styles from './Welcome.module.css';
import hand from '../../assets/hand.svg'
import Btn from '../Btn/Btn';

const Welcome = ({setWelcome}) => (
    <div className={styles.welcome}>
        <h1 className={styles.header}>
            <img className={styles.headerIcon} src={hand} />
            <p className={styles.headerText}>Welcome!</p>
        </h1>
        <p className={styles.text}>
            In English language, words can be categorized according to their syntactic functions, which is known as "Part of Speech".
            Your job is to choose what kind of word is the word shown to you.
        </p>
        <Btn onClick={_ => setWelcome(false)} text='Start'/>
    </div>
);

export default Welcome