import React from 'react'
import Btn from '../Btn/Btn';
import Gauge from '../Gauge/Gauge';
import styles from './Rank.module.css';

const Rank = ({rank, setIsFinished}) => {
  return (
    <div className={styles.rankBoard}>
      <p className={styles.note}>
        The number below represent the rank (The percentage of scores below the given final score)
      </p>
        <Gauge rank={rank} />
        <Btn text='Try again' onClick={_ => setIsFinished(false)}/>
    </div>
  )
}

export default Rank