const BASEURL = 'https://mhy-quiz-server.onrender.com/rank';

const getRank = (score) => fetch(BASEURL, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({score: score})
                            }).then(res => res.json()).then(res => res);

export default getRank;