import React, { useEffect, useRef } from 'react'
import styles from './Gauge.module.css';
import loading from '../../assets/loading.mp3';

const Gauge = ({rank}) => {
    const needleRef = useRef();
    const rankRef = useRef();

    useEffect(() => {
        const needle = needleRef.current;
        const ranking = rankRef.current;

        if(!needle || !ranking) return;

        const audio = new Audio(loading);
        audio.play();
        
        let counter = 0;

        const interval = setInterval(() => {

            if(counter === rank) {
                audio.pause()
                return clearInterval(interval);
            }

            counter++;

            needle.style.transform = `rotate(${1.8 * counter}deg)`;
            ranking.textContent = `${counter} %`;
        }, [40])
    }, [])
  return (
    <>
        <div className={styles.gauge}>
            <div className={styles.sliceColors}>
                <div className={styles.sliceItem}></div>
                <div className={styles.sliceItem}></div>
                <div className={styles.sliceItem}></div>
            </div>
            <div ref={needleRef} className={styles.needle}></div>
            <div className={styles.gaugeCenter}>
                <p ref={rankRef} className={styles.rank}>0 %</p>
            </div>    
        </div>
    </>
  )
}

export default Gauge