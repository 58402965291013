import React, { useEffect, useState } from 'react'
import styles from './Home.module.css';
import Qs from '../Qs/Qs';
import getRank from '../../services/getRank';
import Rank from '../Rank/Rank';
import Welcome from '../Welcome/Welcome';
import Loader from '../Loader/Loader';
import Error from '../Error/Error';

const Home = () => {
  const [score, setScore] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rank, setRank] = useState(null);
  const [welcome, setWelcome] = useState(true);

  useEffect(() => !welcome  && setIsLoading(true), [welcome]);
  useEffect(() => isError && setIsLoading(false), [isError]);

  useEffect(() => {
    if(!isFinished) {
      return setRank(0)
    };

    setIsLoading(true);

     getRank(score)
    .then(res => {
      setRank(res.rank);
      setScore(0);
      setIsLoading(false);
    })
    .catch(_ => setIsError(true))

  }, [isFinished]);
  
  return (
    <>
      {isLoading && <Loader />}
      {isError && <Error />}
      <div className={styles.home}>
        {welcome ? <Welcome setWelcome={setWelcome} />
        : !isFinished ? <Qs score={score} setScore={setScore} setIsFinished={setIsFinished}
        setIsLoading={setIsLoading} setIsError={setIsError} />
        : rank ? <Rank rank={rank} setIsFinished={setIsFinished}/> : null}
      </div>
    </>
  )
}

export default Home;